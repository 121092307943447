import { Button, Modal } from '@wordpress/components';

import Icons from './icons.json';

export default function IconSelector(props) {
  const { isOpen, closeModal, setIcon, color } = props;
  const btnStyle = {
    "width": "56px",
    "height": "56px",
  };
  const iconStyle = {
    "top": "0",
    "left": "0"
  };
  return (
    <>
    { isOpen  && (
      <Modal title={ __( 'Choix de l\'icône' ) } onRequestClose={ closeModal }>
        <div className="icons flex flex-wrap justify-center">
          {Icons.map(name => (
            <Button
              name={ name }
              type="button"
              key={ name }
              onClick={() => {
                closeModal();
                setIcon(name);
              }}
              className="icon-button relative"
              style={btnStyle}
            >
              <i style={iconStyle} className={'absolute material-icons md-56 '+color} aria-hidden="true">{ name }</i>
            </Button>
          ))}
        </div>
      </Modal>
    ) }
    </>
  );
};
