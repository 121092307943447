import "../b10-cta";
import MapBlock from "./map.js";

const root = document.getElementById("map-root");
if (root !== null) {
  const attributes = JSON.parse(root.querySelector("pre").innerHTML);
  const innerBlock = root.querySelector("div");
  if (innerBlock !== null) {
    attributes.innerBlock = innerBlock.outerHTML;
  }
  const element = <MapBlock {...attributes} />
  ReactDOM.render(element, root);
}
